.about-us-page{
    padding-top: 5vmin;
}

.about-us-header{
    font-family: 'Inter-Black';
    text-transform: uppercase;
    font-size: 450%;
    line-height: 120%;
    text-align: left;
    padding-left: 5vmin;
    margin-bottom: 5vh;
}   

/* .about-us-header::after{
    content: "";
    height: 2.5vmin;
    width: 2.5vmin;
    background-color: var(--highLightColor);
    border-radius: 50%;
    display: inline-block;
    margin-left: 15px;
  } */

.about-tagline{
    font-family: 'Inter-Regular';
    font-weight: 900;
    font-size: 130%;
    margin-top: 15vh;
    text-align: right;
    width: 70%;
    margin-left: auto;
    margin-right: 10vmin;
    margin-bottom: 5vmin;
    text-align: left;
}

.about-para{
    font-family: 'Inter-Regular';
    font-size: 110%;
    width: 70%;
    text-align: center;
}

.about-us-benifits-section{
    padding-top: 15vmin;
    padding-bottom: 20vmin;
}

.au-benifit{
    border-bottom: solid 1px rgb(149, 149, 149);
    padding: 1vmin;
    display: flex;
    align-items: center;
    margin-bottom: 5vmin;
}

.au-benefit-image *{
    animation: float_icon 3s infinite ease-in-out;
}

@keyframes float_icon {
    0% {
        transform: scale(1) rotateZ(0) translateX(0);
    }
    50% {
        transform: scale(0.99) rotateZ(-2deg) translateX(-1px);
    }
}

.benifit-text{
    font-family: 'Inter-Black';
    font-size: 170%;
    text-align: left;
    text-transform: uppercase;
}

.benefit-detail-content {
    font-family: 'Inter-Regular';
    font-size: 55%;
    text-align: left;
    text-transform: none;
}

.au-benefit-image{
    background-color: var(--cardColor3);
    padding: 1vmin 5vmin 1vmin -1vmin;
    border-radius: 25% 69% 24% 25% / 18% 25% 50% 25%;
}   

.about-us-card{
    background-color: var(--cardColor1);
    height: fit-content;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 15vmin;
    min-height: 50vh;
    padding-bottom: 5vmin;
}

.au-card-header{
    font-family: 'Inter-Black';
    font-size: 350%;
    color: white;
}

.au-card-body{
    font-family: 'Inter-Regular';
    font-size: 140%;
    color: white;
}

@media (max-width: 768px) {
    .about-us-header{
        font-size: 270%;
        margin-top: 5vh;
    }  

    .about-tagline{
        font-size: 130%;
        margin-top: 10vh;
        /* text-align: right; */
        width: 80%;
        margin-left: auto;
        margin-right: 10vmin;
        margin-bottom: 7vmin;
    }
    
    .about-para{
        font-size: 130%;
        width: 80%;
        margin-left: auto;
        margin-right: 10vmin;
    }

    .au-card-header{
        font-size: 250%;
    }

    .about-us-card{
        min-height: 35vh;
    }

    .au-card-body{
        margin-top: 5vmin;
        font-size: 130%;
    }

    .au-benefit-image {
        transform: scale(0.5);
    }
    
    
}