  @font-face {
    font-family: 'Inter-Bold';
    /* src: url('../assets/fonts/Inter-Bold.woff2') format('woff2') */
    src: local('Arial');
  }

  @font-face {
    font-family: 'Inter-Black';
    /* src: url('../assets/fonts/Inter-Black.woff2') format('woff2'); */
    /* src: url('../assets/fonts/poppins-extrabold-webfont.woff2') format('woff2') */
    src: url('../assets/fonts/BebasNeue-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter-ExtraBold';
    src: url('../assets/fonts/Inter-ExtraBold.woff2') format('woff2')
  }

  @font-face {
    font-family: 'Inter-Regular';
    /* src: url('../assets/fonts/Inter-Regular.woff2') format('woff2') */
    /* src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2') */
    src: local('Arial');
  }

  @font-face {
    font-family: 'Inter-Thin';
    /* src: url('../assets/fonts/Inter-Thin.woff2') format('woff2'); */
    src: url('../assets/fonts/Roboto-Thin.woff2') format('woff2')

  }

  .element {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAACNJREFUCNdjYKAswMSswDMPAYMFcwIMYYmBiogCFBRFQRzAUGACOaUBgsAJLAAAAAElFTkSuQmCC"), auto;
  }
  


.home-page{
    overflow-x: hidden;
    height: fit-content;
}

.our-header{
  font-size: 400%;
  font-family: 'Inter-Black';
  text-transform: uppercase;
  margin-top: 10vmin;
  margin-bottom: 10vmin;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
 color: rgb(0, 0, 0);
}

/* .our-header::after{
  content: "";
  height: 2.5vmin;
  width: 2.5vmin;
  background-color: var(--highLightColor);
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
} */

.intro-section{
    height: 100vh;
    width: 100vw;
    display: flex;
    /* background-image: url("../assets/images/bg2.png"); */
    background-position:center;
    background-size: contain; /* Adjust as needed */
    background-repeat: no-repeat; /* Prevent image from repeating */

}

.company-tagline{
  /* bottom: 55vmin; */
  /* left: 13vmin; */
  width: fit-content;
  text-align: left;
  height: fit-content;
  /* position: absolute; */
  margin-bottom: 2vh;
  justify-content: center;
  align-items: center;
  margin-top: 35vh;
  margin-left: 7vw;
}

.company-tagline-text{
  font-family: 'Inter-Regular';
  font-weight: 900;
  text-transform: uppercase;
  font-size: 80%;
  color: rgb(172, 172, 172);
}

.landing-section-text{
    font-family: 'Inter-Black';
    color: rgb(0, 0, 0);
    font-size: 800%;
    /* bottom: 10vmin; */
    /* left: 10vmin; */
    /* margin-top: 20vh; */
    /* position: absolute; */
    margin-left: 7vw;
    text-transform: uppercase;
    width: fit-content;
    height: fit-content;
    text-align: left;
    line-height: 15vmin;
}

/* .landing-section-text::after{
  content: "";
  height: 2.5vmin;
  width: 2.5vmin;
  background-color: var(--highLightColor);
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
} */

.work-with-us-circle{
  background-color: var(--highLightColor);
  position: absolute;
  bottom: 10vmin;
  right: 10vmin;
  border-radius: 130px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotateAndScale 5s infinite;
  position: fixed;
  font-family: 'Inter-Black';
  z-index: 10;
}

@keyframes rotateAndScale {
  0%, 80% {
    transform: rotate(0deg) scale(1);
  }
  90% {
    transform: rotate(10deg) scale(1.15);
  }
}

.work-with-us-circle:nth-child(odd) {
  animation-delay: 5s;
}

.work-with-us{
  text-transform: uppercase;
  width: fit-content;
  padding: 3vmin 1vmin;
  word-spacing: 3px;
  font-family: 'Inter-Black';
  color: rgb(0, 0, 0);
  font-weight: 900;
  font-size: 150%;
  letter-spacing: 1px;
  line-height: 120%;
  color: white;
  word-spacing: 1px;

}

.mini-about-us-section{
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.mini-about-us-header{
  font-family: 'Inter-Black';
  /* font-size: 300%; */
  text-transform: uppercase;
  margin-bottom: 2vmin;
}


.mini-about-us-body{
  margin-bottom: 5vmin;
  /* width: 80%; */
  margin-left: auto;
  margin-right: auto;
  font-size: 110%;
}

.about-us-button{
  display: flex;
  justify-content: center;
}

@keyframes slides1 {
  0% { transform: translateX(0); }
	100% { transform: translateX(-50%)}
}

@keyframes slides2 {
  0% { transform: translateX(-50%); }
	100% { transform: translateX(0)}
}

.our-clients-section{
  padding: 20vmin 0vmin ;
  height: fit-content;
}


.our-clients-container{
  overflow: hidden;
	width: auto;
  display: flex;
  flex-direction: column;
}


.our-client-header-comtainer{
  display: flex;
  flex-direction: row;
}

.oc-slider1{
  display: inline-block;
  animation: 30s slides1 infinite linear;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0;
  margin-bottom: 10vmin;
  margin-left: 10vmin;
}

.oc-slider1:hover{
  animation-play-state: paused;
}

.oc-slider2:hover{
  animation-play-state: paused;
}

.oc-slider2{
  display: inline-block;
  animation: 30s slides2 infinite linear;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: fit-content;
  margin: 0;
}

.our-client{
  width: auto;
  height: 7vmin;
  margin-right: 6vmin;
}

.service-card-section{
 height: fit-content;
 position: absolute;
 left: 0;
}

.service-card{
  height: 75vh;
  position: sticky;
  top: 1vh;
  border-radius: 25px;
  margin-bottom: 1vmin;
  /* padding-top: 5vmin; */
  padding-left: 1vmin;
}

.card1{
  background-color: var(--cardColor1);
}

.card2{
  background-color: var(--cardColor2);
}

.card3{
  background-color: var(--cardColor3);
}


.service-card-header{
  font-family: 'Inter-Black';
  font-size: 360%;
  text-transform: uppercase;
  text-align: left;
  margin-left: 10vmin;
}

.service-card-body{
  font-size: 100%;
  font-family: 'Inter-Regular';
  text-align: left;
  color: rgb(50, 50, 50);
}

.service-card-category{
    width: fit-content;
    /* color: white; */
    color: #000;
    font-family: 'Inter-Regular';
}

.how-we-work-section{
  height: fit-content;
  margin-bottom: 10vmin;
  padding-top: 30vmin;
}

.how-we-work-header{
  font-size: 250%;
  font-family: 'Inter-Black';
  text-transform: uppercase;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  width: fit-content;
  text-align: left;
}

.how-we-work-body{
  text-align: left;
}

.image-placeholder{
  border-radius: 25px 0 0 25px;
  max-width: 100%;
  max-height: 100%;
}

.know-more-button{
  margin-left: auto;
  margin-right: auto;
}

.contact-section{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10vmin;
  padding-bottom: 10vmin;
}

input {
  outline: none;
  border: none;
  display:block;
  border-radius: 50px;
  border: solid 1px rgb(136, 136, 136);
  background-color: transparent;
  font-size: 100%;
}

.input-box{
  font-size: 80%;
}

textarea{
  outline: none;
  border: solid 1px rgb(136, 136, 136);
  border-radius: 50px;
  background-color: transparent;
}

.form-submit-button{
  margin-top: 8vmin;
  margin-bottom: 5vmin;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

/* Laptop mini */
@media (max-height: 800px) {
  .landing-section-text {
    font-size: 600%;
  }
}

/* Mobile */
@media (max-width: 768px) {

  /* .intro-section {
    background-image: url("../assets/images/bg2_mobile.png");
  } */

  .our-header {
    font-size: 400%;
  }

  .company-tagline {
    margin-top: 35vh;
  }

  .company-tagline-text {
    font-size: 120%;
  }

  .landing-section-text{
    font-size: 575%;
    line-height: 15vmin;
    left: 0;
    bottom: 20vmin;
  }

  .work-with-us-circle{
    border-radius: 130px;
    width: 90px;
    height: 90px;
  }

  .work-with-us{
    font-size: 150%;
  }
  .mini-about-us-header {
    line-height: 120%;
  }

  .mini-about-us-body{
    font-size: 130%;
    text-align: center;
  }

  .about-us-button{
    font-size: 85%;
  }

  .our-client{
    height: 10vmin;
  }

  .service-card-header{
    font-size: 280%;
    line-height: 125%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vmin;
  }

  .service-card-button{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .service-card-body{
    font-size: 130%;
  }

  .service-card-category {
    font-size: 130%;
  }

  .how-we-work-section{
    padding-top: 0vmin;
  }

  .how-we-work-body{
    font-size: 130%;
  }

  input {
    border-radius: 20px;
  }

  textarea{
    border-radius: 20px;
    min-height: 20vh;
  }

  .input-box{
    font-size: 130%;
  }
}
/* 
::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-thumb:vertical {
  background: var(--highLightColor); 
}

::-webkit-scrollbar-track {
  background: transparent; 
} */
