.header-section{
    padding-top: 5vmin;
    width: 100vw;
}

.header-section-fixed {
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: var(--background);
    height: 100vh;
}

.company-logo{
    height: auto;
    width: 20vw;
}

.header-expanded{
    padding-top: 5vh;
    padding-left: 10vw;
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: ease all 1s;
    z-index: 1;
    /* animation: from-left-animation 0.2s ease-in-out forwards; */
}

@keyframes from-left-animation{
    0%{
        transform: translateY(-90%);
        opacity: 1;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
    
}

.header-expanded-heading{
    font-size: 150%;
    font-family: 'Inter-Bold';
    color: rgb(162, 161, 161);
    text-align: left;
    padding-left: 2vw;
    /* animation: show-text 1.5s forwards; */
    /* opacity: 0; */
    transform: translateY(-50%);
    margin-top: 5vh;
    margin-bottom: 3vh;
}

@keyframes show-text {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }
}

.header-content{
    font-size: 200%;
    font-family: 'Inter-Black';
    text-transform: uppercase;
    text-align: left;
    color: rgb(255, 255, 255);
    display: block;
    /* animation: show-text 2.5s forwards; */
    /* opacity: 0; */
    transform: translateY(-50%);
}

.header-content:hover{
    color: rgb(127, 127, 127);
}

.header-close-icon{
    z-index: 2;
}


@media (max-width: 768px) {
    .header-section{
        padding-top: 8vmin;
    }

    .header-content{
        font-size: 200%;
        margin-left: 2vw;
    }

    .company-logo{
        height: auto;
        width: 35vw;
    }
}
