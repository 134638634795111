
.service-component{
    padding-top: 10vmin;
}

.serive-header{
    font-size: 500%;
    font-family: 'Inter-Black';
    padding-left: 0;
    text-align: left;
}

.service-title{
    font-size: 240%;
    font-family: 'Inter-Thin';
    font-weight: 100;
    margin-bottom: 10vmin;
    font-weight: 900;
    text-align: left;
}

.serive-image{
}

.why-service-explanation{
    margin-left: auto;
    margin-right: auto;
    font-family: 'Inter-Thin';
    font-weight: 900;
    font-size: 150%;
    margin-bottom: 15vmin;
    padding-top: 15vmin;
}

.fixed-scroll-section{
    padding-left: 5vw;
    padding-right: 5vw;
    background-color: var(--cardColor1);
    position: relative;
    height: fit-content;
    padding-top: 10vh;
    text-transform: uppercase;
    font-weight: 900;
}

.fixed-scroll-left-section{
    position: sticky;
    height: fit-content;
    color: rgb(255, 255, 255);
    font-size: 300%;
    font-family: 'Inter-Black';
    justify-content: center;
    align-items: center;
    top:40%;
    padding-bottom: 25vh;
} 

.fixed-scroll-left-section::after{
    content: "?";
    border-radius: 50%;
    display: inline-block;
    margin-left: 15px;
}

.fixed-scroll-right-section{
    text-align: left;
    color: rgb(0, 0, 0);
}

.fs-right-section-header {
    font-size: 130%;
    font-family: 'Inter-Black';
    padding: 20px;
   
}

.fs-right-section-body{
    font-size: 90%;
    font-family: 'Inter-Thin';
    font-weight: 900;
    margin-bottom: 7vh;
}

.four-cards-section{
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    flex-direction: row;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
    /* background-color: #fff8f8;
    opacity: 1;
    background-image:  linear-gradient(#adadad 0.7000000000000001px, transparent 0.7000000000000001px), linear-gradient(to right, #adadad 0.7000000000000001px, #fff8f8 0.7000000000000001px);
    background-size: 90px 40px; */
}


.color-card{
    border-radius: 40px;
    padding: 3vmin 3vmin;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.1);

}

.color-card:hover {
    transition: ease all 0.5s;
    /* background-color: rgba(0,0,0,0.2); */
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.329);
}

.color-card:hover .color-card-header {
    transform: scale(1.05);
    margin: 10px;
    transition: ease all 0.5s;
}

.color-card-1{
    min-height: 30vh;
    width: 40vw;
    /* background-color: var(--cardColor1); */
    border-radius: 25px;
    /* animation: shapeChanger1 10s forwards infinite; */
}

@keyframes shapeChanger1 {
    0% {
        border-radius: 25px;
    }
    50% {
        border-radius: 85% 15% 87% 13% / 28% 83% 17% 72%;
    }
    100% {
        border-radius: 25px;
    }   
}

.color-card-2{
    min-height: 40vh;
    width: 35vw;
    margin-top: 5vh;
    /* background-color: var(--highLightColor); */
    border-radius: 25px;
    /* animation: shapeChanger2 13s forwards infinite; */
}


@keyframes shapeChanger2 {
    0% {
        border-radius: 25px;
    }
    50% {
        border-radius: 68% 32% 100% 0% / 17% 100% 0% 83% ;
    }
    100% {
        border-radius: 25px;
    }   
}

.color-card-3{
    min-height: 50vh;
    width: 30vw;
    margin-left: 3vw;
    /* background-color: var(--cardColor3); */
    border-radius: 25px;
    /* animation: shapeChanger3 15s forwards infinite; */
}

@keyframes shapeChanger3 {
    0% {
        border-radius: 25px;
    }
    50% {
        border-radius: 100% 0% 85% 15% / 24% 75% 25% 76% ;
    }
    100% {
        border-radius: 25px;
    }   
}


.color-card-4{
    min-height: 20vh;
    width: 30vw;
    margin-top: 5vh;
    margin-left: 3vw;
    /* background-color: var(--cardColor2); */
    border-radius: 25px;
    /* animation: shapeChanger4 17s forwards infinite; */
}


@keyframes shapeChanger4 {
    0% {
        border-radius: 25px;
    }
    50% {
        border-radius: 99% 1% 85% 15% / 20% 72% 28% 80% ;
    }
    100% {
        border-radius: 25px;
    }   
}

.color-card-header{
    font-size: 180%;
    font-family: 'Inter-Bold';
}

.color-card-body{
    font-family: 'Inter-Thin';
    font-weight: 900;
}



@media (max-width: 768px) {

    .serive-header{
        font-size: 220%;
    }

    .service-title{
        font-size: 150%;
    }
    .why-service-explanation{
        font-size: 150%;
    }


    .four-cards-section{
        display: flex;
        flex-direction: column;
        width: 100vw;
        transform: translateX(-9px);
        background-image: url("../assets/images/bg2_mobile_four_card.png");
    }

    .color-card{
        width: 80vw;
        margin: 0;
        margin-bottom: 5vh;
    }

    .color-card-1, .color-card-2, .color-card-3, .color-card-4 {
        min-height: 35vh;
    }

    .color-card-header{
        font-size: 180%;
    }

    .color-card-body{
        font-size: 130%;
    }

    .fixed-scroll-section{
        padding-top: 5vh;
    }

   .fixed-scroll-left-section{
        font-size: 220%;
        padding-bottom: 0;
        top:20px;
        background-color: var(--cardColor1);
   }
}
