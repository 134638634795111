/* ---solid color buttton--- */

.solid-color-button{
    background-color: var(--highLightColor);
    border-radius: 25px;
    padding: 1vmin 2.5vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    border: solid 1px var(--highLightColor);
}

.solid-button-text{
    position: relative;
    color: rgb(255, 255, 255);
    text-align: center;
}

.solid-color-button:hover{
    background-color:inherit;
    border: solid 1px var(--highLightColor);
    box-shadow: 0px 0px 10px 0px rgba(74, 252, 235, 0.5);
}

.solid-color-button:hover .solid-button-text {
    color: #000;
}

.solid-color-button svg {
    color: #fff;
}

.solid-color-button:hover svg {
    color: #000;
}



/* ---outlined color buttton--- */

.outlined-button{
    background-color: transparent;
    position: relative;
    width: fit-content;
    padding: 1vmin 5vmin;
    border: solid 2px rgb(63, 63, 63);
    border-radius: 25px;
}

.outlined-button:hover{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.outlined-button-text{
    font-size: 100%;
    font-weight: 500;
    color: white;
}

@media (max-width: 768px) {
    .solid-color-button{
        min-width: 60vw;
    }

    .solid-button-text {
        font-size: 180%;
    }
    .outlined-button{
        border: solid 1px rgb(0, 0, 0);
    }
    .outlined-button-text {
        font-size: 110%;
    }
  }