.FAQ-section{
    height: calc(10vmin + fit-content);
    margin-right: auto;
    margin-left: auto;
    width: 65%;
    padding-top: 10vh;

    padding-bottom: 10vh;
}

.FQA-header{
    word-spacing: 1px;
    color: #000000;
    transition: ease all 0.5s;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5vmin 2vmin;
    margin-bottom: 3vmin;
    background: black;
}

.QA-section{
    border-top: solid 1.5px rgb(21, 21, 21);
    padding-top: 2vmin;
    transition: ease all 0.1s;
    padding: 3.5vmin;
}

.active-QA-section{
    transition: ease all 2s;
    border-image: black;
    animation: temp 0.5s forwards;
}

@keyframes temp {
    0%{
        border-width: 0;
    }
    100%{
        border-width: 100%;
        border-image: black;
    }
}


.FAQ-question::before{
}

.FAQ-question{
    font-family: 'Inter-Black';
    word-spacing: 1px;
    color: #000000;
    transition: ease all 0.1s;
    font-size: 250%;
}

.active-question{
    font-family: 'Inter-Black';
    word-spacing: 1px;
    color: #000000;
    font-size: 145%;
    transition: ease all 0.1s;
    font-size: 250%;
}

.FAQ-answer{
    font-family: 'Inter-Regular';
    word-spacing: 1px;
    color: #2b2b2b;
    font-size: 100%;
    height: 0;
    overflow: hidden;
    transition: ease all 0.5s;
}

.active-answer{
    font-family: 'Inter-Regular';
    word-spacing: 1px;
    color: #040404;
    font-size: 100%;
    height: auto;
    height: fit-content;
    margin-bottom: 1vmin;
    padding-bottom: 2vmin;
    padding-top: 1vmin;
}


.rotate-icon{
    transition: transform 5s ease;
}

@media (max-width: 768px) {
    .FAQ-section{
        width: 85%;
    }
    .FAQ-question{
        font-size: 185%;
        text-align: left;
    }

    .active-question{
        font-size: 185%;
        text-align: left;
    }

    .FAQ-answer{
        color: #2b2b2b;
        font-size: 130%;
    }

    .active-answer{
        font-size: 130%;
        text-align: left;
    }
}
