.contact-us-section {
  position: relative;
  width: 100vw;
  min-height: 60vh;
}

.contact-detail-section {
  background-color: var(--cardColor1);
}

.address-detail-section {
  background-color: black;
}

.detail-section-card {
  min-height: 45%;
  border-radius: 15px;
  color: #fff;
  text-align: left;
}

.cu-detail-heading {
  font-family: 'Inter-Bold';
  font-size: 100%;
  text-transform: uppercase;
}

.cu-detail-content {
  font-family: 'Inter-Black';
  font-size: 150%;
  /* margin-bottom: 5vh; */
}

.map-container {
  border: 0;
  min-height: 35vh;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  /* filter: invert(100%); */
}