
.footer {
	position: absolute;
	width: 100vw;
	height: fit-content;
	min-height: 30vh;
	
	padding: 20px;
	padding-top: 8vmin;
	padding-bottom: 8vmin;
	
	color: #e5e1e1;
	background: rgb(22, 22, 22);
	font-family: 'Inter-Regular';
	font-size: 80%;
	border-radius: 50px 50px 0 0; 
	z-index: 15;
}

.img-logo {
	height: 8vh;
	width: auto;
}

.heading-text{
  color: white;
  font-size: 120%;
}

.footer-company {
	text-align: right;
	display: flex;
}

.footer-logo {
	max-width: 250px;
	max-height: 200px;
}

.footer-description {
	font-size: 100%;
	text-align: left;
	padding: 50px 30px;
	width: 25vw;
}

.footer-address {
	text-align: left;
	font-size: 100%;
	padding: 30px 0 10px 30px;
	justify-content: center;
}

.footer-contact {
	text-align: left;
	font-size: 100%;
	padding: 30px 0 30px 30px;
}

.footer-dev {
	font-size: 80%;
	background: #000000;
	color: #ffffff;
	position: absolute;
	bottom: 0%;
	width: 100%;
	padding: 10px;
	padding-bottom: 10px;
	/* font-family: 'Arial'; */
	font-family: "Comic Sans MS";
}

.codespice {
	text-decoration: none;
	color:rgb(132, 233, 0);
	font-weight: 600;
	cursor: pointer;
}

@media (max-width: 768px) {
	.footer-description {
		font-size: 135%;
	}
	
	.footer-address {
		font-size: 135%;
	}

	.footer-contact {
		font-size: 135%;
	}
}
